<template>
  <div>
    <b-row v-for="(data, index) in sections" :key="index">
      <b-img
        class="otomotivImg mt-0"
        :src="data.image"
        fluid
        alt="Responsive image"
      />
      <div class="container ikincikisim">
        <b-row>
          <b-col md="12">
            <b-card class="aboutCard">
              <b-card-text>
                <h2 class="display-4 aboutTitle mb-0">
                  {{ data.title }}
                </h2>
                <hr />
                <div v-html="data.text"></div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
} from "bootstrap-vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
  },
  props: {
    sections: Array,
  },

  /* created() {
    this.getSectorsList();
  },
  methods: {
    getSectorsList() {
      axios
        .get("", {
          img: "",
          title: "",
          text: "",
        })
        .then((result) => {
          if (result.data.success) {
            this.sections = result.data.data;
          } else {
            console.error("API yanıtında başarısızlık:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
  },*/
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap"); /* Navbar için stil */
.otomotivImg {
  width: 100%;
  height: 500px;
  z-index: 1;
  max-width: 100%;
  object-fit: cover;
}
.aboutTitle {
  font-family: "Roboto", sans-serif;
  color: white;
  text-align: center;
}
.ikincikisim {
  text-align: left;
  font-family: "Prompt", sans-serif;
  font-size: 16px;
}
.aboutCard {
  background-color: #072541d2 !important;
  border: none !important;
  color: white;
  position: relative;
  z-index: 20;
  margin-top: -50px;
  padding: 10px 40px 50px;
}
@media (max-width: 767px) {
  .aboutTitle {
    font-size: 35px;
  }
  .otomotivImg {
    width: 100%;
    height: auto;
    z-index: 1;
    max-width: 100%;
  }
}
</style>
